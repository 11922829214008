import React from 'react'
import {
  GoogleMap,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api';

const containerStyle = {
  width: '250px',
  height: '250px'
};

const MapContainer = ({markerPosition}:{markerPosition: any}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBg9F-ELjZrROsgn0py2RNpES87pxoWIpc"
  })
  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={10}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
  ) : <></>
}

export default MapContainer