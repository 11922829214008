import { transformDate } from ".";
import {
  IBusinessPowerOfAttorney,
  INaturalPerson,
  IVerifiableAttestationDiploma,
  IVerifiableCredential,
  ILithuanianStudentId
} from "../types/credentials";

const parseVerifiableCredential = (credential: any): IVerifiableCredential => {
  const { id, familyName, givenName, firstName, givenNames, identifier, dateOfBirth } = credential.credentialSubject;
  return ({
    id: credential.id,
    key: credential.id,
    type: credential.type.slice(-1)[0],
    issuer: credential.issuer,
    issuanceDate: transformDate(
      credential.issuanceDate,
      {
        showYear: true,
        showSeconds: false,
      },
    ),
    person: {
      id,
      identifier,
      givenName: givenName || firstName || givenNames,
      familyName,
      dateOfBirth
    }
  })
}

const parseBusinessPowerOfAttorney = (credential: any): IBusinessPowerOfAttorney => {
  const verifiableCredentialBase: IVerifiableCredential = parseVerifiableCredential(credential);
  const { email, country, position, personalIdentifierDigest } = credential.credentialSubject;
  return ({
    ...verifiableCredentialBase,
    person: {
      email,
      country,
      position,
      personalIdentifierDigest,
      ...verifiableCredentialBase.person,
    },
    organization: {
      ...credential.credentialSubject.organization
    }
  })
}

const parseGovernmentPowerOfAttorney = (credential: any): IBusinessPowerOfAttorney => {
  const verifiableCredentialBase: IVerifiableCredential = parseVerifiableCredential(credential);
  const { email, country, position, personalIdentifierDigest } = credential.credentialSubject;
  return ({
    ...verifiableCredentialBase,
    person: {
      email,
      country,
      position,
      personalIdentifierDigest,
      ...verifiableCredentialBase.person,
    },
    organization: {
      ...credential.credentialSubject.organization
    }
  })
}

const parseVerifiableAttestationDiploma = (credential: any): IVerifiableAttestationDiploma => {
  const verifiableCredentialBase: IVerifiableCredential = parseVerifiableCredential(credential);
  return ({
    ...verifiableCredentialBase,
    awardingOpportunity: {
      ...credential.credentialSubject.awardingOpportunity
    },
    gradingScheme: {
      ...credential.credentialSubject.gradingScheme
    },
    learningAchievement: {
      ...credential.credentialSubject.learningAchievement
    },
    learningSpecification: {
      ...credential.credentialSubject.learningSpecification
    }
  })
}

const parseNaturalPerson = (credential: any): INaturalPerson => {
  const verifiableCredentialBase: IVerifiableCredential = parseVerifiableCredential(credential);
  const { gender, placeOfBirth, personalIdentifier } = credential.credentialSubject;
  return ({
    ...verifiableCredentialBase,
    person: {
      gender,
      placeOfBirth,
      personalIdentifier,
      ...verifiableCredentialBase.person,
    }
  })
}

const parseLithuanianStudentId = (credential: any): ILithuanianStudentId => {
  const verifiableCredentialBase: IVerifiableCredential = parseVerifiableCredential(credential);
  const { photo, email, personalIdentifier, studentId, position, country } = credential.credentialSubject;
  return ({
    ...verifiableCredentialBase,
    person: {
      photo,
      email,
      country,
      position,
      studentId,
      personalIdentifier,
      ...verifiableCredentialBase.person,
    },
    organization: {
      ...credential.credentialSubject.organization
    }
  })
}

const credentialParsers = {
  parseNaturalPerson,
  parseLithuanianStudentId,
  parseBusinessPowerOfAttorney,
  parseGovernmentPowerOfAttorney,
  parseVerifiableAttestationDiploma,
}

export default credentialParsers