import {
  Layout, Select,
} from 'antd';

import { useTranslation } from 'react-i18next';
import { changeLanguage } from "../../i18n/i18n"

const {
  Header: AntHeader,
} = Layout;
const { Option } = Select;

const Header = () => {
  const { i18n } = useTranslation();
  return (
    <AntHeader>
      <span>Verifier</span>
      <Select
        defaultValue={i18n.resolvedLanguage}
        dropdownMatchSelectWidth={false}
        style={{ width: 60 }}
        onSelect={(language:string) => changeLanguage(language)}
      >
        <Option value="en">EN</Option>
        <Option value="lt">LT</Option>
        <Option value="ru">RU</Option>
      </Select>
    </AntHeader>
  )
}

export default Header