import config from "../config"

export const verifyVP = async (vp: object): Promise<any> => {
  const response = await fetch(config.verifyVPUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ verifiablePresentation: vp })
  })

  return response.json()
}