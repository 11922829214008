import { useMemo } from 'react';
import zustandStore from '../zustand'

interface ITransformDateConfig {
  showYear: boolean
  showSeconds: boolean
}

export const transformDate = (
  date?: string,
  config: ITransformDateConfig = { showYear: true, showSeconds: false },
) => (`
    ${(new Date(date || '').toLocaleDateString('lt-lt')).slice(config.showYear ? 0 : 5)}
    ${new Date(date || '').toLocaleTimeString('lt-lt', {
    hour: '2-digit',
    minute: '2-digit',
    second: config.showSeconds ? '2-digit' : undefined,
})}`);
  
export const fileToJSON = async (jsonFile: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = event => resolve(JSON.parse(event.target?.result as string))
    fileReader.onerror = error => reject(error)
    fileReader.readAsText(jsonFile)
  })
}

export const getCurrentPosition = () => {
  if (window.navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        zustandStore.setState({
        currentPosition: {
          type: "success",
          data: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        }
      })},
      (err) => {
        console.log(err)
        zustandStore.setState({
        currentPosition: {
          type: "error",
          data: { message: err.message, code: err.code },
        }
      })},
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }
    );
  } else {
    zustandStore.setState({
      currentPosition: {
        type: "error",
        data: { message: "No Geolocation API" },
      }
    });
  }
};

export const getClientIP = async () => {
  try{
    const response = await fetch('https://geolocation-db.com/json/');
    const data = await response.json();
    return data.IPv4 ? data.IPv4 : ''
  } catch (err) {
    return err
  }
} 

export const useQuery = () => {
  const search  = window.location.search;
  return useMemo(() => new URLSearchParams(search), [search]);
};