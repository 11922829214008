import create from 'zustand'
import { IPosition } from '../types'

interface VerifierState {
  currentPosition: IPosition | null,
  setCurrentPosition: (position: IPosition) => void
}

const useStore = create<VerifierState>(set => ({
  currentPosition: null,
  setCurrentPosition: (position: IPosition) => set(() => ({ currentPosition: position })),
}))

export default useStore