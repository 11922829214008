const {
  REACT_APP_PROJECT_COMMIT_DATE,
  REACT_APP_PROJECT_COMMIT_SHORT_SHA,
} = process.env;

const config = {
  projectCommitDate: REACT_APP_PROJECT_COMMIT_DATE || '',
  projectCommitShortSHA: REACT_APP_PROJECT_COMMIT_SHORT_SHA || '',
  webSocketUrl: 'wss://api-v2.did.sis.lt/v1/ws',
  verifyVPUrl: 'https://api.verifier.essif.sis.lt/v1/presentations/verify'
};

export default config
