import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import lt from './lt.json';
import en from './en.json';
import ru from './ru.json';

export const defaultNS = 'common'
export const resources = {
  lt,
  en,
  ru,
};

const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS,
  fallbackLng: 'en',
});

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};


export default availableLanguages;
