import {
  Table, Tooltip
} from 'antd';

import Credential from './components/Credential';

import { transformDate } from '../../utils';

import './CredentialsTable.scss'

export type ICredentialsTableItem = {
  id: string,
  // vp: string,
  key: string,
  type: "GovernmentPowerOfAttorney" | "BusinessPowerOfAttorney" | "VerifiableAttestationDiploma" | "NaturalPerson",
  issuer: string,
  schemaLink: string,
  rawCredential: any,
  issuanceDate: string,
  holderFullName: string,
  // status: 'success' | 'warning' | 'error'
}

const MAX_LENGTH = 30;

const CredentialsTable = ({
  credentialsItems,
  loading,
  // validationResults
}: any) => {
  
  const data: ICredentialsTableItem[] = credentialsItems.map((item: any) => {
    const { id, issuer, type, issuanceDate,
      credentialSubject: { givenName, familyName, firstName, givenNames },
      credentialSchema: {id: schemaLink}
    } = item;
    return ({
      id,
      issuer,
      key: id,
      schemaLink,
      type: type.slice(-1)[0],
      issuanceDate: transformDate(
        issuanceDate,
        {
          showYear: true,
          showSeconds: false,
        },
      ),
      holderFullName: `${givenName || firstName || givenNames} ${familyName}`,
      rawCredential: item
    })
  });

  const columns: any = [
    {
      title: 'Credential',
      key: 'credential',
      width: 200,
      render: (rowProps: ICredentialsTableItem) => (
        <span>
          <div>{rowProps.issuanceDate}</div>
          <div>{rowProps.id}</div>
        </span>
      ),
      // sorter: (a: IPayment, b: IPayment) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime(),
    },
    {
      title: 'Issuer',
      key: 'issuer',
      width: 400,
      responsive: ['md'],
      render: (rowProps: ICredentialsTableItem) => {
        const {type, issuer} = rowProps
        return (
          <span>
            <Tooltip
              placement="bottomLeft"
              title={`${issuer}`}
              visible={issuer.length < MAX_LENGTH ? false : undefined}
              // getTooltipContainer={(container) => {
              //   container.onclick = (e) => e.stopPropagation()
              //   return container
              // }}
            >
              {`${issuer.substring(0, MAX_LENGTH - 3)}`}
              {issuer.length < MAX_LENGTH || "..."}
            </Tooltip>
            <div>
              <a
                target="_blank"
                href={rowProps.schemaLink} rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {type}
              </a>
            </div>
          </span>
        )
      },
      // sorter: (a: IPayment, b: IPayment) => a.payee.name.localeCompare(b.payee.name),
      // onFilter: (value: string, rowProps: IPayment) => rowProps.payee.name === value,
      // filters: [...new Set(data.map((item) => item.payee.name))]
      //   .map((item) => ({ text: item, value: item })),
    },
    {
      title: 'Holder',
      key: 'holder',
      width: 300,
      responsive: ['sm'],
      render: (rowProps: ICredentialsTableItem) => {
        const {holderFullName} = rowProps
        return (
          <span>
            <div>
              <Tooltip
                placement="bottomLeft"
                title={`${holderFullName}`}
                visible={holderFullName.length < MAX_LENGTH ? false : undefined}
              >
                {`${holderFullName.substring(0, MAX_LENGTH - 3)}`}
              {holderFullName.length < MAX_LENGTH || "..."}
              </Tooltip>
            </div>
          </span>
      )},
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   width: 100
    // }
  ];

  return (
    <div>
      <Table
        className='table table-expandable'
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender: ((credential: ICredentialsTableItem) => <Credential credential={credential} />),
          expandIcon: () => '',
          columnWidth: 0,
          expandRowByClick: true,
        }}
        onRow={(record: any) => ({
          // onClick: () => history.push(`/board/documents/${record.id}`),
          onClick: () => {
            console.log(record)
            // setChosenPrmId(record.id);
            // setPaymentModalVisible(true);
          },
        })}
        rowClassName="table-row"
        dataSource={data}
        pagination={false}
      />
    </div>
  )
}

export default CredentialsTable
