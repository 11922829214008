import { ICredentialsTableItem } from "../CredentialsTable";
import credentialParsers from '../../../utils/credentialParsers'

import {
  INaturalPerson,
  IBusinessPowerOfAttorney,
  IGovernmentPowerOfAttorney,
  IVerifiableAttestationDiploma,
  ILithuanianStudentId
} from '../../../types/credentials'

const Credential = ({ credential }: { credential: ICredentialsTableItem }) => {
  console.log(credential);
  const { type, rawCredential } = credential;
  if (type === 'NaturalPerson')
    return <NaturalPerson credential={credentialParsers.parseNaturalPerson(rawCredential)} />
  if (type === 'BusinessPowerOfAttorney')
    return <BusinessPowerOfAttorney credential={credentialParsers.parseBusinessPowerOfAttorney(rawCredential)} />
  if (type === 'GovernmentPowerOfAttorney')
    return <GovernmentPowerOfAttorney credential={credentialParsers.parseGovernmentPowerOfAttorney(rawCredential)} />
  if (type === 'VerifiableAttestationDiploma')
    return <VerifiableAttestationDiploma credential={credentialParsers.parseVerifiableAttestationDiploma(rawCredential)} />
  if (type === 'LithuanianStudentId')
    return <LithuanianStudentId credential={credentialParsers.parseLithuanianStudentId(rawCredential)} />
  return <p>This credential is not supported</p>
}

const NaturalPerson = ({ credential }: {credential: INaturalPerson}) => (
  <div>
    <p>{`Id: ${credential.id}`}</p>
    <p>{`Issuance date: ${credential.issuanceDate}`}</p>
    <p>{`Issuer: ${credential.issuer}`}</p>
    <p>Holder:</p>
    <ul>
      <li>{`Id: ${credential.person.id}`}</li>
      <li>{`Given name: ${credential.person.givenName}`}</li>
      <li>{`Family name: ${credential.person.familyName}`}</li>
      <li>{`Date of birth: ${credential.person.dateOfBirth}`}</li>
      <li>{`Gender: ${credential.person.gender}`}</li>
      <li>{`Personal identifier: ${credential.person.personalIdentifier}`}</li>
      <li>{`Place of birth: ${credential.person.placeOfBirth}`}</li>
    </ul>
  </div>
)

const LithuanianStudentId = ({ credential }: {credential: ILithuanianStudentId}) => (
  <div>
    <p>{`Id: ${credential.id}`}</p>
    <p>{`Issuance date: ${credential.issuanceDate}`}</p>
    <p>{`Issuer: ${credential.issuer}`}</p>
    <p>Holder:</p>
    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <ul>
        <li>{`Id: ${credential.person.id}`}</li>
        <li>{`Given name: ${credential.person.givenName}`}</li>
        <li>{`Family name: ${credential.person.familyName}`}</li>
        <li>{`Date of birth: ${credential.person.dateOfBirth}`}</li>
        <li>{`Email: ${credential.person.email}`}</li>
        <li>{`Position: ${credential.person.position}`}</li>
        <li>{`Country: ${credential.person.country}`}</li>
        <li>{`Student Id: ${credential.person.studentId}`}</li>
        <li>{`Identifier: ${credential.person.identifier}`}</li>
        <li>{`Personal identifier: ${credential.person.personalIdentifier}`}</li>
      </ul>
    <img height='200px' src={credential.person.photo} alt="profile logo" />
    </div>
    <p>Organization:</p>
    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <ul>
        <li>{`Id: ${credential.organization.id}`}</li>
        <li>{`Country: ${credential.organization.country}`}</li>
        <li>{`Member type: ${credential.organization.memberType}`}</li>
        <li>{`Auth Icd: ${credential.organization.authorityIcd}`}</li>
        <li>{`Auth Id: ${credential.organization.authorityId}`}</li>
        <li>{`Name: ${credential.organization.name}`}</li>
      </ul>
      <img height='100px' src={credential.organization.logo} alt="profile logo" />
    </div>
  </div>
)

const BusinessPowerOfAttorney = ({ credential }: {credential: IBusinessPowerOfAttorney}) => (
  <div>
    <p>{`Id: ${credential.id}`}</p>
    <p>{`Issuance date: ${credential.issuanceDate}`}</p>
    <p>{`Issuer: ${credential.issuer}`}</p>
    <p>Holder:</p>
    <ul>
      <li>{`Id: ${credential.person.id}`}</li>
      <li>{`Given name: ${credential.person.givenName}`}</li>
      <li>{`Family name: ${credential.person.familyName}`}</li>
      <li>{`Date of birth: ${credential.person.dateOfBirth}`}</li>
      <li>{`Email: ${credential.person.email}`}</li>
      <li>{`Position: ${credential.person.position}`}</li>
      <li>{`Identifier: ${credential.person.identifier}`}</li>
      <li>{`Identifier digest: ${credential.person.personalIdentifierDigest}`}</li>
    </ul>
    <p>Organization:</p>
    <ul>
      <li>{`Id: ${credential.organization.id}`}</li>
      <li>{`Country: ${credential.organization.country}`}</li>
      <li>{`Member type: ${credential.organization.memberType}`}</li>
      <li>{`Auth Icd: ${credential.organization.authorityIcd}`}</li>
      <li>{`Auth Id: ${credential.organization.authorityId}`}</li>
      <li>{`Name: ${credential.organization.name}`}</li>
    </ul>
  </div>
)

const GovernmentPowerOfAttorney = ({ credential }: {credential: IGovernmentPowerOfAttorney}) => (
  <div>
    <p>{`Id: ${credential.id}`}</p>
    <p>{`Issuance date: ${credential.issuanceDate}`}</p>
    <p>{`Issuer: ${credential.issuer}`}</p>
    <p>Holder:</p>
    <ul>
      <li>{`Id: ${credential.person.id}`}</li>
      <li>{`Given name: ${credential.person.givenName}`}</li>
      <li>{`Family name: ${credential.person.familyName}`}</li>
      <li>{`Date of birth: ${credential.person.dateOfBirth}`}</li>
      <li>{`Email: ${credential.person.email}`}</li>
      <li>{`Position: ${credential.person.position}`}</li>
      <li>{`Identifier: ${credential.person.identifier}`}</li>
      <li>{`Identifier digest: ${credential.person.personalIdentifierDigest}`}</li>
    </ul>
    <p>Organization:</p>
    <ul>
      <li>{`Id: ${credential.organization.id}`}</li>
      <li>{`Country: ${credential.organization.country}`}</li>
      <li>{`Member type: ${credential.organization.memberType}`}</li>
      <li>{`Auth Icd: ${credential.organization.authorityIcd}`}</li>
      <li>{`Auth Id: ${credential.organization.authorityId}`}</li>
      <li>{`Name: ${credential.organization.name}`}</li>
    </ul>
  </div>
)

const VerifiableAttestationDiploma = ({ credential }: {credential: IVerifiableAttestationDiploma}) => (
  <div>
    <p>{`Id: ${credential.id}`}</p>
    <p>{`Issuance date: ${credential.issuanceDate}`}</p>
    <p>{`Issuer: ${credential.issuer}`}</p>
    <p>Holder:</p>
    <ul>
      <li>{`Id: ${credential.person.id}`}</li>
      <li>{`Given name: ${credential.person.givenName}`}</li>
      <li>{`Family name: ${credential.person.familyName}`}</li>
      <li>{`Date of birth: ${credential.person.dateOfBirth}`}</li>
      <li>{`Identifier: ${credential.person.identifier}`}</li>
    </ul>
    <p>Awarding Opportunity:</p>
    <ul>
      <li>{`Preffered name: ${credential.awardingOpportunity.awardingBody.preferredName}`}</li>
      <li>{`EIDAS: ${credential.awardingOpportunity.awardingBody.eidasLegalIdentifier}`}</li>
      <li>{`Registration: ${credential.awardingOpportunity.awardingBody.registration}`}</li>
      <li>{`Id: ${credential.awardingOpportunity.awardingBody.id}`}</li>
    </ul>
    <p>Grading scheme:</p>
    <ul>
      <li>{`Id: ${credential.gradingScheme.id}`}</li>
      <li>{`Title: ${credential.gradingScheme.title}`}</li>
      <li>{`Description: ${credential.gradingScheme.description}`}</li>
    </ul>
    <p>Learning achievement:</p>
    <ul>
      <li>{`Id: ${credential.learningAchievement.id}`}</li>
      <li>{`Title: ${credential.learningAchievement.title}`}</li>
    </ul>
    <p>Learning specification:</p>
    <ul>
      <li>{`Id: ${credential.learningSpecification.id}`}</li>
      <li>{`ISCED: ${credential.learningSpecification.iscedfCode}`}</li>
      <li>{`NqfLevel: ${credential.learningSpecification.nqfLevel}`}</li>
    </ul>
  </div>
)

export default Credential