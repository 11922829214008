import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import zustandStore from './zustand'

import { QrCode } from "@sis-lab/web-ui-components";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import {
  Spin,
  Upload,
  message,
  Button,
} from 'antd'
import {CloudUploadOutlined} from '@ant-design/icons';

import Header from './components/Header/Header';
import MapContainer from './components/MapContainer/MapContainer';
import CredentialsTable from './components/CredentialsTable/CredentialsTable';

import config from './config';
import { fileToJSON, getClientIP, getCurrentPosition, useQuery } from './utils';
import { verifyVP } from './services';

import './App.css';
import './i18n/i18n'
import './global.scss'

const App = () => {
  const ws = useRef<WebSocket | null>(null);
  const [loadingCredentials, setLoadingCredentials] = useState<boolean>(false);
  const [countdownIsActive, setCountdownIsActive] = useState<boolean>(false);
  const [idTokenDecoded, setIdTokenDecoded] = useState<any>(null);
  const [qrCodeContent, setQrCodeContent] = useState<string>('');
  const [vpValidations, setVPValidations] = useState<any>([]);
  const [vpCredentials, setVpCredentials] = useState<any>([]);
  const [countdownKey, setCountdownKey] = useState<number>(0);
  const [clientIP, setClientIP] = useState<string>('')
  const [nonce, setNonce] = useState<string>('')
  const { currentPosition } = zustandStore()
  const [t] = useTranslation();
  
  const urlParams = useQuery();
  const nonceParam = urlParams.get('nonce')
  const timeoutParam = urlParams.get('timeout')
  const showGeoParam = urlParams.get('geo')
  const showIPParam = urlParams.get('ip')
  const verifierParam = urlParams.get('verifier')
  const siopShort = urlParams.get('siopShort')
  const CREDENTIALS_TIMEOUT_SECONDS = timeoutParam ? (isNaN(timeoutParam as any) ? 10 : Number(timeoutParam)) : 10
  const showIP = showIPParam ? !(showIPParam === 'false') : true
  const showGeo = showGeoParam ? !(showGeoParam === 'false') : true
  const [vc, setVC] = useState<any>();

  useEffect(() => {
    if(showGeo) getCurrentPosition()
    if(showIP) getClientIP()
      .then(ip => setClientIP(ip))
      .catch((err: any) => console.log(err))
    if (verifierParam) fetch(`https://vc-list-xorea8g6ea4hpj.s3.eu-west-1.amazonaws.com/${verifierParam}.json`)
      .then((resp: any) => resp.json().then((vcjson: any) => {setVC(vcjson)}) )
      .catch((err: any) => console.log(err))
    
    if (nonceParam) fetch(`https://api-v2.did.sis.lt/v1/siop/session/${nonceParam}`)
      .then((resp: any) => resp.json().then((session: any) => {
        console.log(session)
        if (session.id_token_decoded && session.id_token_decoded.payload && session.id_token_decoded.payload.verifiable_presentations) {
          iterateOverVPs(session.id_token_decoded.payload.verifiable_presentations)
        } else {
          message.error('SIOP session has expired or not found')
        }
      }))
      .catch((err: any) => console.log(err))
    
    ws.current = new WebSocket(config.webSocketUrl);
    ws.current.onopen = (event: any) => {
      ws.current!.send(JSON.stringify({
          "op": "generate_siop_did_auth_request",
          "data": {
              "scope": "openid did_authn vp_any"
          }
      }));
    };

    ws.current.onmessage = async (event: any) => {
        const json = JSON.parse(event.data);

        if (json.uri) {
            setNonce(json.nonce)
            if (!siopShort) setQrCodeContent(`https://api-v2.did.sis.lt/v1/siop/request/${json.nonce}`)
            else setQrCodeContent(json.uri)
        } else if (json.id_token) {
          iterateOverVPs(json.id_token_decoded.payload.verifiable_presentations)
          setIdTokenDecoded(json.id_token_decoded)
        }
    };

    ws.current.onclose = () => { console.log("closed websocket") }

    const wsCurrent = ws.current;

    return () => {
        wsCurrent.close();
    };
  }, [])

  const checkVP = async (vp: any) => {
    const verifyResult = await verifyVP(vp.presentation)
    if (verifyResult.detail) throw Error(t('errors.presentationUpload.wrongStructure'))
    else return {id: vp.presentation.id, verify_result: verifyResult}
  }
  const iterateOverVPs = async (vps: any) => {
    setVpCredentials([])
    setVPValidations([])
    setIdTokenDecoded('')
    setCountdownKey(countdownKey + 1)
    setCountdownIsActive(false)
    setLoadingCredentials(true)
    const credentials = []
    const newVpValidations = []
    for (const vp of vps) {
      try {
        newVpValidations.push(await checkVP(vp))
        credentials.push(...vp.presentation.verifiableCredential)
      } catch (err: any) {
        console.log(err.message);
        message.error(err.message);
        break;
      }
    }
    if (credentials.length) {  
      setVpCredentials(credentials)
      setVPValidations(newVpValidations)
      setCountdownIsActive(true)
    } 
    setLoadingCredentials(false)
  }

  const handlePresentationUpload = async (file: any) => {
    if (file.type === 'application/json') {
      const json = await fileToJSON(file)
      if (!json.verifiable_presentations) message.error(t('errors.presentationUpload.wrongStructure'))
      else iterateOverVPs(json.verifiable_presentations)
    } else {
      message.error(t('errors.presentationUpload.wrongType'))
    }
  }
  const renderCountdownTime = ({ remainingTime }: { remainingTime: number }) => {
    if (loadingCredentials) {
      return <div className="timer">{t('countdown.loading')}</div>;
    }

    if ((remainingTime === +CREDENTIALS_TIMEOUT_SECONDS && !countdownIsActive) || remainingTime === 0) {
      return <div className="timer">{t('countdown.waiting')}</div>;
    }

    return (
      <div className="timer">
        <div className="value">{`${remainingTime} ${t('countdown.seconds')}`}</div>
        <div className="text"></div>
      </div>
    );
  };

  const isImage = (url: string) => (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url))

  const renderVcContainer = () => {
    if (!vc || !vc.credentialSubject) return false
    const {
      id,
      legalName,
      domainName,
      legalAddress,
      VATRegistration,
      legalPersonalIdentifier,
    } = vc.credentialSubject
    const domainIsImage = domainName ? isImage(domainName) : undefined
    return (
      <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'10px'}}>
        {domainIsImage ? <img src={domainName} alt='domain logo' width='50px' /> : ''}
        <ul>
          {id ? <li>{`Id: ${id}`}</li> : ''}
          {legalName ? <li>{`Legal name: ${legalName}`}</li> : ''}
          {legalAddress ? <li>{`Legal address: ${legalAddress}`}</li> : ''}
          {VATRegistration ? <li>{`VAT: ${VATRegistration}`}</li> : ''}
          {legalPersonalIdentifier ? <li>{`Legal personal identifier: ${legalPersonalIdentifier}`}</li> : ''}
          {(domainName && !domainIsImage) ? <li>{`Domain: ${<a href={domainName}>{domainName}</a>}`}</li> : ''}
        </ul>
      </div>
    )
  }
  // ! status column yellow green red
  return (
  <>
    <Header />
    <div className="container">
      <main className="main">
        <Spin />
        {showIP && (
          <span style={ {marginBottom:'15px'} }>
            {
              clientIP
              ? clientIP === 'err' ? t('header.ip.error') :`${t("header.ip.detected")} ${clientIP}`
              : t('header.ip.detecting')
            }
          </span>
        )}
        {vc ? renderVcContainer() : ''}
        {/* <h1>
          Validate Business Power of Attorney presentation
        </h1> */}
        {qrCodeContent &&
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center', flexWrap:'wrap' }}>
            {(currentPosition && currentPosition.data.lat && showGeo)
              ? <div className='flex-item'> <MapContainer markerPosition={currentPosition.data} /></div>
              : false}
            <QrCode data={qrCodeContent} width={285} height={285} />
            <div
              className='flex-item'
            >
            <CountdownCircleTimer
              size={150}
              strokeWidth={7}
              key={countdownKey}
              isPlaying={countdownIsActive}
              duration={+CREDENTIALS_TIMEOUT_SECONDS}
              colorsTime={[10, 6, 3, 0]}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              onComplete={() => {
                setVPValidations([])
                setVpCredentials([])
                setCountdownKey(countdownKey + 1)
                setCountdownIsActive(false)
              }}
            >
              {renderCountdownTime}
            </CountdownCircleTimer>
            </div>
          </div>
        }
        <Upload
          className='upload upload--medium'
          multiple={false}
          showUploadList={false}
          // openFileDialogOnClick={false}
          beforeUpload={handlePresentationUpload}
        >
          <p className="upload-icon">
            <CloudUploadOutlined />
          </p>
          <p className="upload-text">Click or drag file to this area to upload</p>
          </Upload>
          <Button type='primary' target='_blank' href={`https://api-v2.did.sis.lt/v1/siop/request/${nonce}`}>{t("buttons.qr")}</Button>
        {/* <span>{nonce ? `Nonce: ${nonce}` : false}</span> */}
        <CredentialsTable
          loading={loadingCredentials}
          credentialsItems={vpCredentials}
          validationResults={vpValidations}
        />
        {idTokenDecoded && 
          <div>
            <hr></hr>
            {idTokenDecoded.header.kid}
          </div>
        }
        {vpValidations.length && 
          <div>
              {vpValidations.map((vp: any) => (
                <div key={vp.id}>
                  <h2>{vp.id}</h2>
                  <h3>Checks</h3>
                  <ul>
                  {vp.verify_result.checks.map((check: string) => <li key={check}>{check}</li>)}
                  </ul>
                  <h3>Errors</h3>
                  <ul>
                  {vp.verify_result.errors.map((err: string) => <li key={err}>{err}</li>)}
                  </ul>
                  <h3>Warnings</h3>
                  <ul>
                  {vp.verify_result.warnings.map((warn: string) => <li key={warn}>{warn}</li>)}
                  </ul>
                  <hr></hr>
                </div>))}
          </div>
        }
      </main>

      <footer className="footer">
        ©2022 Systems integration solutions
      <div className="version">{`${config.projectCommitDate} ${config.projectCommitShortSHA}`}</div>
      </footer>
    </div>
  </>
  )
}

export default App;
